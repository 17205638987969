<template>
  <el-card class="info_card">
    <div slot="header">
      <span>已审核分子条目信息历史记录</span>
    </div>
    <div>
      <el-table v-loading="loading" :data="infoList" border style="width: 100%">
        <el-table-column prop="name" label="分子名称" width="180">
        </el-table-column>
        <el-table-column prop="molecular_formula" label="分子式" width="180">
        </el-table-column>
        <el-table-column prop="cas_number" label="CAS号码"></el-table-column>
        <el-table-column prop="pub_chem_cid" label="CID"></el-table-column>
        <el-table-column
          prop="audit_user_name"
          label="审核人"
        ></el-table-column>
        <el-table-column prop="update_time" label="最近更新时间">
          <template slot-scope="scope">
            <span>{{ timestampToTime(scope.row.update_time) }}</span>
          </template></el-table-column
        >
        <el-table-column prop="status" label="状态" width="100"
          ><template slot-scope="scope">
            <el-tag
              :type="
                scope.row.status == 1
                  ? 'info'
                  : scope.row.status == 2
                  ? 'success'
                  : scope.row.status == 3
                  ? 'warning'
                  : 'danger'
              "
              >{{
                scope.row.status == 1
                  ? "待审核"
                  : scope.row.status == 2
                  ? "已通过"
                  : scope.row.status == 3
                  ? "未通过"
                  : "已下线"
              }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="135">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleShowDetail(scope.$index, scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <el-dialog
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="50rem"
      >
        <el-descriptions title="分子基本信息" border :column="2">
          <el-descriptions-item label="分子名称">{{
            row.name
          }}</el-descriptions-item>
          <el-descriptions-item label="分子式">{{
            row.molecular_formula
          }}</el-descriptions-item>
          <el-descriptions-item label="CAS号码">{{
            row.cas_number
          }}</el-descriptions-item>
          <el-descriptions-item label="CID">{{
            row.pub_chem_cid
          }}</el-descriptions-item>
          <el-descriptions-item label="审核人">{{
            row.audit_user_name
          }}</el-descriptions-item>
          <el-descriptions-item label="状态"><el-tag
              :type="
                row.status == 1
                  ? 'info'
                  : row.status == 2
                  ? 'success'
                  : row.status == 3
                  ? 'warning'
                  : 'danger'
              "
              >{{
                row.status == 1
                  ? "待审核"
                  : row.status == 2
                  ? "已通过"
                  : row.status == 3
                  ? "未通过"
                  : "已下线"
              }}</el-tag
            ></el-descriptions-item>
          <el-descriptions-item label="条目创建时间">{{
            row.create_time
          }}</el-descriptions-item>
          <el-descriptions-item label="条目最近更新时间">{{
            row.update_time
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="审核信息" border :column="1">
          <el-descriptions-item label="审核意见">
            {{ row.remark }}
          </el-descriptions-item>
        </el-descriptions>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      row: {},
      //编辑人基本信息
      basicInfo: {},
      //分页数据--start
      pageSize: 10,
      pageNum: 1,
      total: 0,
      //分页数据--end
      infoList: [],
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.loading = true;
      this.$store
        .dispatch("moleculeInfoAuditRecord/select", {
          pagesize: this.pageSize,
          pagenum: this.pageNum,
        })
        .then((res) => {
          var { data } = res;
          this.infoList = data.info_list;
          this.total = data.total;
          this.loading = false;
        });
    },
    //分页控件函数--start
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getInfo();
    },
    //分页控件函数--end
    //查看详细信息
    handleShowDetail(index, row) {
      this.row = row;
      this.dialogVisible = true;
    },
    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>

<style>
</style>