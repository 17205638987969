var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticClass:"info_card"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("已审核分子条目信息历史记录")])]),_c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.infoList,"border":""}},[_c('el-table-column',{attrs:{"prop":"name","label":"分子名称","width":"180"}}),_c('el-table-column',{attrs:{"prop":"molecular_formula","label":"分子式","width":"180"}}),_c('el-table-column',{attrs:{"prop":"cas_number","label":"CAS号码"}}),_c('el-table-column',{attrs:{"prop":"pub_chem_cid","label":"CID"}}),_c('el-table-column',{attrs:{"prop":"audit_user_name","label":"审核人"}}),_c('el-table-column',{attrs:{"prop":"update_time","label":"最近更新时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.timestampToTime(scope.row.update_time)))])]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"状态","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":scope.row.status == 1
                ? 'info'
                : scope.row.status == 2
                ? 'success'
                : scope.row.status == 3
                ? 'warning'
                : 'danger'}},[_vm._v(_vm._s(scope.row.status == 1 ? "待审核" : scope.row.status == 2 ? "已通过" : scope.row.status == 3 ? "未通过" : "已下线"))])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"135"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.handleShowDetail(scope.$index, scope.row)}}},[_vm._v("查看")])]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.pageNum,"page-sizes":[5, 10, 15, 20],"page-size":_vm.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}),(_vm.dialogVisible)?_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"50rem"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-descriptions',{attrs:{"title":"分子基本信息","border":"","column":2}},[_c('el-descriptions-item',{attrs:{"label":"分子名称"}},[_vm._v(_vm._s(_vm.row.name))]),_c('el-descriptions-item',{attrs:{"label":"分子式"}},[_vm._v(_vm._s(_vm.row.molecular_formula))]),_c('el-descriptions-item',{attrs:{"label":"CAS号码"}},[_vm._v(_vm._s(_vm.row.cas_number))]),_c('el-descriptions-item',{attrs:{"label":"CID"}},[_vm._v(_vm._s(_vm.row.pub_chem_cid))]),_c('el-descriptions-item',{attrs:{"label":"审核人"}},[_vm._v(_vm._s(_vm.row.audit_user_name))]),_c('el-descriptions-item',{attrs:{"label":"状态"}},[_c('el-tag',{attrs:{"type":_vm.row.status == 1
                ? 'info'
                : _vm.row.status == 2
                ? 'success'
                : _vm.row.status == 3
                ? 'warning'
                : 'danger'}},[_vm._v(_vm._s(_vm.row.status == 1 ? "待审核" : _vm.row.status == 2 ? "已通过" : _vm.row.status == 3 ? "未通过" : "已下线"))])],1),_c('el-descriptions-item',{attrs:{"label":"条目创建时间"}},[_vm._v(_vm._s(_vm.row.create_time))]),_c('el-descriptions-item',{attrs:{"label":"条目最近更新时间"}},[_vm._v(_vm._s(_vm.row.update_time))])],1),_c('el-descriptions',{attrs:{"title":"审核信息","border":"","column":1}},[_c('el-descriptions-item',{attrs:{"label":"审核意见"}},[_vm._v(" "+_vm._s(_vm.row.remark)+" ")])],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("确 定")])],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }